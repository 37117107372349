import React from 'react'

import Jumbotron from '../components/Jumbotron/Jumbotron'
import ContentContainer from '../components/ContentContainer/ContentContainer'
import Button from '../components/Button/Button'
import FlexBox, { Justification } from '../components/FlexBox/FlexBox'
import TitledContent from '../components/TitledContent/TitledContent'
import FullWidthSection, { PaddingOption } from '../components/FullWidthSection/FullWidthSection'
import Paragraph from '../components/Paragraph/Paragraph'
import MainLayout from '../layouts/MainLayout'
import { COMPANY_NAME } from '../constants'

import gleanLogo from './About/images/logo-formerly.png'

import './About/about.scss'

const About: React.SFC = () => (
  <MainLayout title={COMPANY_NAME}>
    <Jumbotron title="Our Company is now called Glean" image={gleanLogo}>
      <Paragraph>We're still the same people, we're just going by the name Glean now.</Paragraph>
      <div className="app__jumbotron-buttons">
        <Button to="https://glean.co/our-company/about-us/" className="app__jumbotron-button" primary forGlean>
          Learn more about us
        </Button>
      </div>
    </Jumbotron>

    <FullWidthSection paddingTop={PaddingOption.TINY} paddingBottom={PaddingOption.NONE}>
      <ContentContainer>
        <TitledContent title="Becoming Glean" center>
          <Paragraph>
            For 14 years, we've been Sonocent. Now, we've changed our name to Glean. We're excited about what this means
            for our future, but you can still expect the same level of service and care as you always have.
          </Paragraph>
          <FlexBox justify={Justification.CENTER} vertical className="about__pills-flexbox">
            <Button to="http://glean.co/blog/goodbye-sonocent-hello-glean/" className="app__pill" forGlean>
              Why we changed our name
            </Button>
            <Button to="https://glean.co/our-company/our-story/" className="app__pill" forGlean>
              Our story
            </Button>
          </FlexBox>
        </TitledContent>
      </ContentContainer>
    </FullWidthSection>

    <FullWidthSection paddingTop={PaddingOption.TINY} paddingBottom={PaddingOption.NONE}>
      <ContentContainer>
        <TitledContent title="What this means for Sonocent Audio Notetaker customers" center>
          <Paragraph>
            We'll continue to support Audio Notetaker until 2025, so there's no need to worry. However, we do recommend
            that customers transition to Glean over the next few years. To learn more, read through our FAQs below.
          </Paragraph>
          <FlexBox justify={Justification.CENTER} vertical className="about__pills-flexbox">
            <Button to="https://help.glean.co/article/260-company-name-change-faqs" className="app__pill" forGlean>
              Customer FAQs
            </Button>
            <Button to="https://glean.co/contact/" className="app__pill" forGlean>
              Contact us
            </Button>
          </FlexBox>
        </TitledContent>
      </ContentContainer>
    </FullWidthSection>

    <FullWidthSection paddingTop={PaddingOption.TINY} paddingBottom={PaddingOption.NONE}>
      <ContentContainer>
        <TitledContent title="The Glean Team is growing!" center>
          <Paragraph>
            We're always looking for talented new colleagues to join the Glean Team! To learn more about us and the
            roles available, visit our careers site.
          </Paragraph>
          <FlexBox justify={Justification.CENTER} className="about__pills-flexbox">
            <Button to="https://glean.co/our-company/careers" className="app__pill" forGlean>
              Careers at Glean
            </Button>
          </FlexBox>
        </TitledContent>
      </ContentContainer>
    </FullWidthSection>

    <FullWidthSection paddingTop={PaddingOption.TINY} paddingBottom={PaddingOption.NONE}>
      <ContentContainer>
        <TitledContent title="Contact us" center>
          <Paragraph>You can contact us via our new company website.</Paragraph>
          <FlexBox justify={Justification.CENTER} className="about__pills-flexbox">
            <Button to="https://glean.co/contact/" className="app__pill" forGlean>
              Contact us
            </Button>
          </FlexBox>
        </TitledContent>
      </ContentContainer>
    </FullWidthSection>
  </MainLayout>
)

export default About
